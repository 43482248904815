/**
 * @name getFormattedNumber
 * @param {num} value numeric signal value
 * @returns formatted string representation of numeric signal param
 */
export const getFormattedNumber = (value: number) => {
    let formattedString = '';
    if (value < 1000) {
        formattedString = formatBelowThousand(value);
    } else if (value < 1000000) {
        formattedString = formatBelowMillion(value);
    } else {
        formattedString = formatAboveMillion(value);
    }
    return formattedString;
};

const formatBelowThousand = (value: number) => {
    return value.toString();
};

const formatBelowMillion = (value: number) => {
    if (value % 1000 === 0) {
        return `${Math.floor(value / 1000)}k`;
    } else {
        return `${(Math.floor(value / 100) / 10)
            .toFixed(1)
            .replace(/\.0$/, '')}k`;
    }
};

const formatAboveMillion = (value: number) => {
    if (value % 1000000 === 0) {
        return `${Math.floor(value / 1000000)}M`;
    } else {
        return `${(Math.floor(value / 100000) / 10)
            .toFixed(1)
            .replace(/\.0$/, '')}M`;
    }
};

export const yieldToMain = () => {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
};
