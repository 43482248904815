import React from 'react';
import { OrganizerContext } from '../../context';
import useYielded from '../../hooks/useYielded';
import { Organizer } from '../../types';
import { ContactOrganizerButton } from '../ContactOrganizerButton';
import { SocialWidget } from '../SocialWidget';
import './OrganizerAbout.scss';
import { OrganizerSummary } from './OrganizerSummary';

export const OrganizerAboutContent: React.FunctionComponent = () => {
    const organizer = React.useContext(OrganizerContext) as Organizer;

    const hasSocialLinks =
        !!organizer.website || !!organizer.twitter || !!organizer.facebook;

    return (
        <>
            <OrganizerSummary organizer={organizer} />
            {hasSocialLinks && (
                <div className="organizer-profile-header__social-widget">
                    <SocialWidget
                        website={organizer.website}
                        twitter={organizer.twitter}
                        facebook={organizer.facebook}
                    />
                </div>
            )}
            <div className="contact-organizer-wrapper">
                <ContactOrganizerButton />
            </div>
        </>
    );
};

interface OrganizerAboutProps {
    type: 'mobile' | 'desktop';
}

export const OrganizerAbout: React.FunctionComponent<OrganizerAboutProps> = (
    props,
) => {
    const yielded = useYielded();

    const { type } = props;

    if (type === 'mobile') {
        return (
            <section className="eds-show-down-sw about-container">
                {yielded && <OrganizerAboutContent />}
            </section>
        );
    } else {
        return (
            <section className="eds-show-up-mn about-container">
                {yielded && <OrganizerAboutContent />}
            </section>
        );
    }
};
