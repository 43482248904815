import React, { Component } from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import { formatUrl } from 'url-lib';
import get from 'lodash/get';
import pull from 'lodash/pull';
import omit from 'lodash/omit';
import isBoolean from 'lodash/isBoolean';

import { setWindowLocation } from '@eventbrite/http';
import { getUrlPath } from '@eventbrite/path-utils';
import { trackEventFromState } from '@eventbrite/site-analytics';
import { displayJourneyUsingMetatags } from '@eventbrite/branchio-utils';
import { track } from '@eventbrite/datalayer-library';

import FollowNotification from '../components/FollowNotification';
import FollowGDPRModal from '../components/FollowGDPRModal';
import FollowLoginModal from '../components/FollowLoginModal';
import {
    followOrganizer as followOrganizerAPI,
    getOrganizers as getOrganizersAPI,
    unfollowOrganizer as unfollowOrganizerAPI,
} from '../api/organizer';
import { getUser } from '../api/user';
import { normalizeOrganizers } from '../api/transformations';
import { computeMergedState, getInitialSharedState } from '../utils/state';
import {
    createFollowNotificationContainer,
    createGDPRContainer,
    createLoginModalContainer,
    expireOrganizerCookie,
    getOrganizerIdFromCookie,
    shouldShowGDPRModal,
} from '../utils/modals';
import { logEvent } from '@eventbrite/statsig';

import {
    actionToGAActionMap,
    ERROR_FOLLOW,
    ERROR_ORG_INFO,
    ERROR_UNFOLLOW,
    ERROR_USER_INFO,
    FOLLOW_ORGANIZER_SUCCESS,
    GLOBAL_NAV_HEIGHT,
    LOGIN_CONFIRMATION_PATH,
    UNAUTHORIZED_ERROR_CODE,
} from '../constants';

// onViewOrganizer is passed an empty function because it was previously used to call a google analytics tracking func.
// we no longer use GA, but refactoring this further would require a lot of changes in the codebase
// that are outside the scope the current ticket targeting the removal of GA code from the discover app.

// this is also why trackGAEvent is passed an empty function

const addFollow = (HighLevelComponent) =>
    class Follow extends Component {
        static propTypes = {
            organizers: PropTypes.arrayOf(PropTypes.string).isRequired,
            // eslint-disable-next-line
            gaSettings: PropTypes.object || {},
            gaCategory: PropTypes.string || '',

            pageArea: PropTypes.string,

            organizerData: PropTypes.objectOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                    followedByYou: PropTypes.bool.isRequired,
                    profilePicture: PropTypes.string,
                    numUpcomingEvents: PropTypes.number,
                    numFollowers: PropTypes.number,
                    customDataPoint: PropTypes.string,
                }),
            ),
            isAuthenticated: PropTypes.bool,
            isGDPRCountry: PropTypes.bool,
            userId: PropTypes.string,
            gaActionSuffix: PropTypes.string,
            loginOptions: PropTypes.shape({
                referrer: PropTypes.string,
                shouldOpenNewTab: PropTypes.bool,
                shouldUseSigninConfirmationRedirect: PropTypes.bool,
                isLoginExperimentActive: PropTypes.bool,
            }),
            notificationContainerClassName: PropTypes.string,
        };

        static childContextTypes = {
            subscribeToContextUpdates: PropTypes.func,
            unsubscribeFromContextUpdates: PropTypes.func,
            onFollow: PropTypes.func,
            onUnFollow: PropTypes.func,
            onRemoveOrganizer: PropTypes.func,
            onViewOrganizer: PropTypes.func,
        };

        static defaultProps = {
            loginOptions: {},
        };

        constructor(props) {
            super(props);
            this._isMounted = false;
            this.subscriptions = [];

            this.state = computeMergedState(getInitialSharedState(props));

            this.trackGAEvent = (gaProperties) =>
                trackEventFromState(
                    { gaSettings: props.gaSettings },
                    gaProperties,
                );
        }
        getChildContext() {
            return {
                subscribeToContextUpdates: this.subscribeToContextUpdates,
                unsubscribeFromContextUpdates:
                    this.unsubscribeFromContextUpdates,
                onFollow: this._handleOnFollow,
                onUnFollow: this._handleOnUnFollow,
                onRemoveOrganizer: this._handleRemoveOrganizer,
                onViewOrganizer: () => {},
            };
        }

        componentDidUpdate() {
            if (
                JSON.stringify(this.props.organizers) !==
                JSON.stringify(this.state.organizers)
            ) {
                this.setState(computeMergedState(this.props), () =>
                    this._hydrateOrganizerData(),
                );
            }
        }

        componentDidMount() {
            this._isMounted = true;
            this._hydrateOrganizerData();
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        _hydrateOrganizerData = () => {
            const { organizers } = this.state;

            getOrganizersAPI(organizers)
                .catch(this._handleError.bind(null, '', ERROR_ORG_INFO))
                .then((response) => {
                    if (this._isMounted && response) {
                        const { organizers: organizerResponse = [] } = response;
                        this.setState(
                            (prevState) => ({
                                organizerData: {
                                    ...prevState.organizerData,
                                    ...normalizeOrganizers(organizerResponse),
                                },
                            }),
                            this.updateSubscribers,
                        );

                        this._shouldFollowOrganizerFromCookie();
                    }
                });
        };

        _shouldFollowOrganizerFromCookie = () => {
            const { organizerData } = this.state;
            const organizerIdFromCookie = getOrganizerIdFromCookie();

            if (
                organizerIdFromCookie &&
                organizerData[organizerIdFromCookie] &&
                !organizerData[organizerIdFromCookie].followedByYou &&
                organizerIdFromCookie ===
                    organizerData[organizerIdFromCookie].id
            ) {
                this._checkAuthenticatedStatusWithCache().then(
                    (isAuthenticated) => {
                        if (isAuthenticated) {
                            this._followOrganizer(organizerIdFromCookie);
                            this._clearOrganizerCookie();
                        }
                    },
                );
            }
        };

        _clearOrganizerCookie = () => {
            expireOrganizerCookie();
        };

        _followOrganizer = (organizerId) =>
            followOrganizerAPI(organizerId)
                .then((response) => {
                    if (this._isMounted) {
                        this._updateFollowState(organizerId, response);
                        this._trackFollowEvent(organizerId);
                        this._informUser(organizerId);
                        logEvent(FOLLOW_ORGANIZER_SUCCESS, 'Follow');
                    }
                })
                .catch(() => this._handleError(organizerId, ERROR_FOLLOW));

        _unFollowOrganizer = (organizerId) =>
            unfollowOrganizerAPI(organizerId)
                .then((response) => {
                    this._updateFollowState(organizerId, response);
                    this._trackUnfollowEvent(organizerId);
                    logEvent(FOLLOW_ORGANIZER_SUCCESS, 'Unfollow');
                })
                .catch(() => this._handleError(organizerId, ERROR_UNFOLLOW));

        _updateFollowState = (organizerId, response = {}) => {
            const { followedByYou } = response;

            if (followedByYou !== undefined) {
                this.setState((prevState) => {
                    // Ensure organizerData exists or initialize it if undefined
                    const currentOrganizerData =
                        prevState.organizerData?.[organizerId] || {};
                    const currentNumFollowers =
                        currentOrganizerData.numFollowers || 0; // Default to 0 if undefined

                    return {
                        ...prevState,
                        organizerData: {
                            ...prevState.organizerData,
                            [organizerId]: {
                                ...currentOrganizerData,
                                followedByYou,
                                numFollowers: followedByYou
                                    ? currentNumFollowers + 1
                                    : Math.max(currentNumFollowers - 1, 0), // Prevents negative follower counts
                            },
                        },
                    };
                }, this.updateSubscribers);
            }
        };

        _handleError = (organizerId, action) => {
            const { pageArea } = this.props;
            if (action === ERROR_FOLLOW) {
                track({
                    eventName: 'FollowOrganizerFailed',
                    eventData: {
                        organizerId: organizerId,
                        pageArea: pageArea,
                    },
                });
            } else if (action === ERROR_UNFOLLOW) {
                track({
                    eventName: 'UnfollowOrganizerFailed',
                    eventData: {
                        organizerId: organizerId,
                        pageArea: pageArea,
                    },
                });
            }
            this._renderNotification(organizerId, action);
        };

        _informUser = async (organizerId) => {
            const { isGDPRCountry, userId } = this.props;
            const shouldShowModal = await shouldShowGDPRModal(
                isGDPRCountry,
                userId,
                organizerId,
            );
            this._renderNotification(organizerId);
            if (shouldShowModal) {
                this._renderGDPRModal(organizerId);
            }
        };

        _trackFollowEvent = (organizerId) => {
            const { pageArea } = this.props;
            track({
                eventName: 'FollowOrganizerSuccessful',
                eventData: {
                    organizerId: organizerId,
                    pageArea: pageArea,
                },
            });
        };

        _trackFollowAttempt = (organizerId) => {
            const { pageArea } = this.props;
            track({
                eventName: 'FollowOrganizerAttempt',
                eventData: {
                    organizerId: organizerId,
                    pageArea: pageArea,
                },
            });
        };

        _trackUnfollowEvent = (organizerId) => {
            const { pageArea } = this.props;
            track({
                eventName: 'UnfollowOrganizerSuccessful',
                eventData: {
                    organizerId: organizerId,
                    pageArea: pageArea,
                },
            });
        };

        _getGAEventParamsFor = (action, organizerId) => {
            const { gaCategory, gaActionSuffix } = this.props;
            const { organizerData } = this.state;
            const actionName = actionToGAActionMap[action];
            const dimensions = gaActionSuffix
                ? { dimension4: gaActionSuffix }
                : {};

            return {
                action: actionName,
                category: gaCategory,
                label: get(organizerData, [organizerId, 'name']),
                dimensions,
            };
        };

        updateSubscribers = (nextState = this.state) => {
            this.subscriptions.forEach((cb) => {
                cb(nextState);
            });
        };

        subscribeToContextUpdates = (cb) => {
            this.subscriptions = [...this.subscriptions, cb];
            cb(this.state);
        };

        unsubscribeFromContextUpdates = (cbToUnsub) => {
            this.subscriptions = this.subscriptions.filter(
                (cb) => cb !== cbToUnsub,
            );
        };

        _handleLoggedOutFollow = (organizerId) => {
            const { loginOptions } = this.props;
            const { organizerData } = this.state;
            const referrer = loginOptions.referrer || getUrlPath();

            if (loginOptions.shouldUseSigninConfirmationRedirect) {
                const url = formatUrl(LOGIN_CONFIRMATION_PATH, {
                    referrer,
                    organizerId,
                    action: 'followOrganizer',
                    title: get(organizerData, [organizerId, 'name']),
                    image: get(organizerData, [organizerId, 'profilePicture']),
                });

                if (loginOptions.shouldOpenNewTab) {
                    return window.open(url);
                }

                return setWindowLocation(url);
            }

            return this._renderLoginModal(organizerId);
        };

        _checkAuthenticatedStatusWithCache = () => {
            const {
                isAuthenticated: isAuthenticatedFromState,
                userId: userIdFromState,
            } = this.state;

            const authenticatedStatusIsDefined = isBoolean(
                isAuthenticatedFromState,
            );
            const userLoggedOut =
                authenticatedStatusIsDefined &&
                !isAuthenticatedFromState &&
                !userIdFromState;
            const userLoggedInWithData =
                authenticatedStatusIsDefined &&
                isAuthenticatedFromState &&
                userIdFromState;

            if (userLoggedInWithData || userLoggedOut) {
                return Promise.resolve(isAuthenticatedFromState);
            }

            return getUser()
                .then(({ id }) => {
                    if (this._isMounted) {
                        return this._setAuthentication(true, id);
                    }

                    return null;
                })
                .catch(({ response }) => {
                    if (response.status === UNAUTHORIZED_ERROR_CODE) {
                        return this._setAuthentication(false);
                    }

                    this._renderNotification('', ERROR_USER_INFO);

                    return this._setAuthentication(undefined);
                });
        };

        _setAuthentication = (isAuthenticated, userId) => {
            this.setState({
                isAuthenticated,
                userId,
            });

            return isAuthenticated;
        };

        _handleOnFollow = (organizerId, { callback } = {}) => {
            const { pageArea } = this.props;
            this._checkAuthenticatedStatusWithCache().then(
                (isAuthenticated) => {
                    displayJourneyUsingMetatags({
                        name: 'FollowClicked',
                        content: '1',
                        extraData: {
                            customActionParams: {
                                OrganizerId: organizerId,
                            },
                        },
                    }); // Add metatag to display branchIO journeys
                    if (isAuthenticated) {
                        this._followOrganizer(organizerId).then(() => {
                            callback && callback();
                        });
                    } else {
                        this._trackFollowAttempt(organizerId);
                        this._handleLoggedOutFollow(organizerId);
                    }
                    track({
                        eventName: 'FollowOrganizerClick',
                        eventData: {
                            organizerId: organizerId,
                            pageArea: pageArea,
                        },
                    });
                },
            );
        };

        _handleOnUnFollow = (organizerId, { callback } = {}) => {
            const { pageArea } = this.props;
            this._unFollowOrganizer(organizerId).then(() => {
                callback && callback();
            });
            track({
                eventName: 'UnfollowOrganizerClick',
                eventData: {
                    organizerId: organizerId,
                    pageArea: pageArea,
                },
            });
        };

        _handleRemoveOrganizer = (organizerId) => {
            this._removeOrganizer(organizerId);
        };

        _removeOrganizer = (organizerId) => {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    organizerData: omit(prevState.organizerData, organizerId),
                    organizers: pull(prevState.organizers, organizerId),
                }),
                this.updateSubscribers,
            );
        };

        _renderLoginModal = (organizerId) => {
            const { loginOptions } = this.props;
            const { organizerData } = this.state;
            const organizer = get(organizerData, [organizerId]);

            if (organizer) {
                render(
                    <FollowLoginModal
                        organizerId={organizerId}
                        organizerName={get(organizer, 'name')}
                        trackGAEvent={() => {}}
                        organizerProfilePicture={get(
                            organizer,
                            'profilePicture',
                            '',
                        )}
                        getSharedState={() => this.state}
                        setSharedState={(newState) => {
                            this.setState(newState);
                        }}
                        onChangeSharedState={(cb) => {
                            this.subscribeToContextUpdates(cb);
                        }}
                        referrer={loginOptions.referrer}
                        shouldOpenNewTab={loginOptions.shouldOpenNewTab}
                        isLoginExperimentActive={
                            loginOptions.isLoginExperimentActive
                        }
                    />,
                    createLoginModalContainer(),
                    () => {
                        this.setState(
                            (prevState) => ({
                                ...prevState,
                                loginModalShown: true,
                            }),
                            this.updateSubscribers,
                        );
                    },
                );
            }
        };

        _renderGDPRModal = () => {
            const { gaCategory } = this.props;
            const { userId } = this.state;

            render(
                <FollowGDPRModal
                    userId={userId}
                    trackGAEvent={() => {}}
                    gaCategory={gaCategory}
                    getSharedState={() => this.state}
                    setSharedState={(newState) => {
                        this.setState(newState);
                    }}
                    onChangeSharedState={(cb) => {
                        this.subscribeToContextUpdates(cb);
                    }}
                />,
                createGDPRContainer(),
                () => {
                    this.setState(
                        (prevState) => ({
                            ...prevState,
                            gdprModalShown: true,
                        }),
                        this.updateSubscribers,
                    );
                },
            );
        };

        _renderNotification = (organizerId, errorActionType = '') => {
            render(
                <FollowNotification
                    organizerId={organizerId}
                    getSharedState={() => this.state}
                    setSharedState={(newState) => {
                        this.setState(newState);
                    }}
                    onChangeSharedState={(cb) => {
                        this.subscribeToContextUpdates(cb);
                    }}
                    errorActionType={errorActionType}
                    topOffset={
                        this.props.notificationContainerClassName
                            ? 0
                            : GLOBAL_NAV_HEIGHT
                    }
                />,
                createFollowNotificationContainer(
                    this.props.notificationContainerClassName,
                ),
                () => {
                    this.setState(
                        (prevState) => ({
                            ...prevState,
                            followNotificationShown: true,
                        }),
                        this.updateSubscribers,
                    );
                },
            );
        };

        render() {
            return <HighLevelComponent {...this.props} />;
        }
    };

export default addFollow;
