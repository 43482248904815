import { Badge } from '@eventbrite/eds-badge';
import { Tabs } from '@eventbrite/eds-tabs';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React, { useEffect, useRef, useState } from 'react';
import { createBreakpoint, useLocation } from 'react-use';
import { StickyHeaderContent as StickyHeaderContentRedesign } from '../components-redesign/Main';
import { ORGANIZER_ABOUT_TAB } from '../constants';
import { SectionsIds } from '../constants/sectionsIds';
import { useOrganizerContext } from '../context';
import {
    DiscoveryEventsFromView,
    Organizer,
    OrganizerCollection,
    OrganizerCollections as OrganizerCollectionsInterface,
} from '../types';
import { FollowOrganizer } from './FollowOrganizer';
import './Main.scss';
import { MainSection } from './MainSection';
import { OrganizerAbout } from './OrganizerAbout';
import { OrganizerCollections } from './OrganizerCollections';
import { OrganizerEventFollowExperiment } from './OrganizerEventFollowExperiment';
import { OrganizerEvents } from './OrganizerEvents';
import {
    TabNavigation,
    TabNavigationHeader,
    TabNavigationSection,
    TabNavLink,
    TabSection,
} from './TabNavigation';
import { useStickyHeader } from './TabNavigation/hooks';

interface MainProps {
    organizer: Organizer;
    events: DiscoveryEventsFromView;
    organizerCollections: OrganizerCollectionsInterface<OrganizerCollection>;
    userId: string;
    enableOrganizerProfileRedesign: boolean;
    localeSubdirectory?: string;
}
interface StickyHeaderContentProps {
    userId: string;
}

const useBreakPoint = createBreakpoint({ desktop: 792, mobile: 0 });
const SECTIONS_INTERSECTION_OPTIONS = {
    rootMargin: '0px 0px -25% 0px',
};
const StickyHeaderContent: React.FunctionComponent<StickyHeaderContentProps> = (
    props,
) => {
    const organizer = useOrganizerContext() as Organizer;
    const [size, setSize] = React.useState('mini');
    const breakPoint = useBreakPoint(useBreakPoint);
    const userId = props.userId;

    React.useEffect(() => {
        const dynamicSize = breakPoint === 'mobile' ? 'mini' : 'standard';
        setSize(dynamicSize);
    }, [breakPoint]);

    return (
        <div className="sticky-header__extra-content">
            <div className="sticky-header__organizer-name eds-text-bl">
                {organizer.name}
            </div>
            <FollowOrganizer
                organizer={organizer}
                size={size}
                userId={userId}
            />
        </div>
    );
};

function getTabSectionClassNames(activeTab: string, sectionId: string): string {
    return `organizer-profile__events-tab-section ${
        activeTab === sectionId ? '' : 'eds-hide'
    }`;
}

export const Main: React.FunctionComponent<MainProps> = (props) => {
    const stickyHeaderContainerRef = useRef<HTMLDivElement>(null);
    const intersectionRef = useRef<HTMLDivElement>(null);
    const isSticky = useStickyHeader(intersectionRef, {
        rootMargin: '-160px 0px 150% 0px',
    });
    const [navTopPosition, setNavTopPosition] = useState(0);
    const [navContentTopPosition, setNavContentTopPosition] = useState(0);
    const [activeTab, setActiveTab] = useState(SectionsIds.EVENTS);

    const hasCollections =
        props.organizerCollections.collections &&
        props.organizerCollections.collections.length > 0;

    const userId = props.userId;

    const TAB_ITEMS = [
        {
            value: 0,
            content: (
                <>
                    <OrganizerEventFollowExperiment events={props.events} />
                </>
            ),
            displayName: gettext('Events'),
        },
        hasCollections
            ? {
                  value: 1,
                  content: (
                      <>
                          <OrganizerCollections
                              organizerCollections={props.organizerCollections}
                              localeSubdirectory={props.localeSubdirectory}
                          />
                      </>
                  ),
                  displayName: gettext('Collections'),
              }
            : null,
        {
            value: 2,
            content: (
                <>
                    <OrganizerAbout type="mobile" />
                    <OrganizerAbout type="desktop" />
                </>
            ),
            displayName: gettext('About'),
        },
    ].filter(Boolean);

    function resetScrollPosition() {
        window.scrollTo(0, 0);
    }

    const location = useLocation();
    useEffect(() => {
        const hash = location.hash.replace('#', '').toLowerCase();
        const sections = Object.values(SectionsIds).map((section) =>
            section.toString(),
        );

        if (sections.includes(hash)) {
            setActiveTab(hash);
        } else {
            setActiveTab(SectionsIds.EVENTS);
        }
    }, [location.hash]);

    // prettier-ignore
    useEffect(() => {
        // we want to only calculate tab's top position when isSticky is true.
        // Since `follow-container` is hidden initially using `display: none`, clientHeight will be zero and we won't get proper top position.
        if (!isSticky || !intersectionRef.current || !stickyHeaderContainerRef.current) return;

        const headingEl = intersectionRef.current.querySelector('.follow-container');
        const tabEl = stickyHeaderContainerRef.current.querySelector('.eds-tabs__navigation') as HTMLUListElement;

        if (!headingEl || !tabEl) return;

        const heightAboveNav = (tabEl.offsetTop - headingEl.clientHeight) + 1; // +1 to overlap tab on top of the heading so that there is no gap in between
        setNavTopPosition(-1 * heightAboveNav);
        setNavContentTopPosition(isSticky ? tabEl.clientHeight + 12 : 0); // 12px margin bottom
    }, [isSticky]);

    useEffect(() => {
        const tabs = document.querySelectorAll('.eds-tabs__item');
        tabs.forEach((tab) => {
            tab.addEventListener('click', resetScrollPosition);
        });

        return () => {
            tabs.forEach((tab) => {
                tab.removeEventListener('click', resetScrollPosition);
            });
        };
    }, []);

    useEffect(() => {
        const tab2 = document.querySelector('#tab2 > button > span');
        const handleClick = () => {
            logEvent(ORGANIZER_ABOUT_TAB);
        };
        if (tab2) {
            tab2.addEventListener('click', handleClick);
            return () => {
                tab2.removeEventListener('click', handleClick);
            };
        }
    }, []);

    return (
        <section
            data-testid="organizer-profile"
            className="organizer-profile main eds-g-cell eds-g-cell-12-12 eds-g-cell-lg-10-12 eds-g-offset-lg-1-12 eds-g-cell--has-overflow"
        >
            <div className="organizer-profile-experiment-container">
                <div
                    ref={stickyHeaderContainerRef}
                    className={`${
                        isSticky ? 'organizer-sticky-container' : ''
                    }`}
                    style={
                        {
                            '--nav-top': navTopPosition + 'px',
                            '--nav-content-top': navContentTopPosition + 'px',
                        } as React.CSSProperties
                    }
                >
                    <div ref={intersectionRef}>
                        <div
                            className={`${
                                isSticky ? 'sticky' : 'sticky-initial'
                            }`}
                        >
                            <div className="follow-container">
                                <h2>{props.organizer.name}</h2>
                                <FollowOrganizer
                                    organizer={props.organizer}
                                    userId={userId}
                                />
                            </div>
                        </div>
                    </div>

                    <Tabs items={TAB_ITEMS} initialSelectedTab={0} />
                </div>
            </div>

            <div className="organizer-profile-control-container">
                <TabNavigation breakPoints={{ desktop: 792, mobile: 0 }}>
                    <TabNavigationHeader
                        stickyNode={
                            props.enableOrganizerProfileRedesign ? (
                                <StickyHeaderContentRedesign userId={userId} />
                            ) : (
                                <StickyHeaderContent userId={userId} />
                            )
                        }
                        stickyContentClassNames="organizer-profile__sticky-header--content eds-g-cell eds-g-cell-12-12 eds-g-cell-lg-10-12 eds-g-offset-lg-1-12 eds-g-cell--has-overflow"
                    >
                        <TabNavLink
                            id="about"
                            containerClassName="eds-show-down-sw"
                            hide="desktop"
                        >
                            {gettext('About')}
                        </TabNavLink>
                        <TabNavLink id="events">{gettext('Events')}</TabNavLink>
                        {hasCollections && (
                            <TabNavLink id="collections">
                                <span style={{ paddingRight: '4px' }}>
                                    {gettext('Collections')}
                                </span>
                                <Badge
                                    text={gettext('New')}
                                    bgColor="success"
                                />
                            </TabNavLink>
                        )}
                    </TabNavigationHeader>
                    <TabNavigationSection>
                        <TabSection
                            hide="desktop"
                            containerClassName="eds-show-down-sw"
                            intersectionOptions={{
                                rootMargin: '-160px 0px 0px 0px',
                            }}
                        >
                            <OrganizerAbout type="mobile" />
                        </TabSection>
                        <TabSection
                            id={SectionsIds.EVENTS.toString()}
                            containerClassName={getTabSectionClassNames(
                                activeTab.toString(),
                                SectionsIds.EVENTS.toString(),
                            )}
                            intersectionOptions={SECTIONS_INTERSECTION_OPTIONS}
                        >
                            <MainSection headerNode={gettext('Events')}>
                                <OrganizerEvents events={props.events} />
                            </MainSection>
                        </TabSection>
                        {hasCollections && (
                            <TabSection
                                id={SectionsIds.COLLECTIONS.toString()}
                                containerClassName={getTabSectionClassNames(
                                    activeTab.toString(),
                                    SectionsIds.COLLECTIONS.toString(),
                                )}
                                intersectionOptions={
                                    SECTIONS_INTERSECTION_OPTIONS
                                }
                            >
                                <MainSection
                                    headerNode={gettext('Collections')}
                                >
                                    <OrganizerCollections
                                        organizerCollections={
                                            props.organizerCollections
                                        }
                                        localeSubdirectory={
                                            props.localeSubdirectory
                                        }
                                    />
                                </MainSection>
                            </TabSection>
                        )}
                    </TabNavigationSection>
                </TabNavigation>
            </div>
        </section>
    );
};
