import {
    CoreApplicationContext,
    CoreGoogleAnalyticsSettings,
} from '@eventbrite/context-gen';
import { RenderGlobalNavOrUndefined } from '@eventbrite/global-nav';
import {
    getLocaleOverride,
    getParentLocale,
    setLanguage,
} from '@eventbrite/i18n';
import { useRealUserMonitoring } from '@eventbrite/real-user-monitoring';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { SiteStructure } from '@eventbrite/site-structure';
import { useConstructor } from '@eventbrite/use-constructor';
import React from 'react';
import { HeaderRedesign } from './components-redesign/Header';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { OrganizerRatingDataHandler } from './components/OrganizerRatingDataHandler';
import {
    GA_ACTION_PROFILE_DROPDOWN,
    GA_CATEGORY_HEADER,
    GA_LABEL_CREATOR_EVENT,
    ORGANIZER_PROFILE_RUM_SAMPLE_RATE,
} from './constants';
import {
    EnvContext,
    OrganizerContext,
    SurfacingExperimentProvider,
    TrustSignalsProvider,
    UserProvider,
} from './context';
import {
    DiscoveryEventsFromView,
    Organizer,
    OrganizerCollection,
    OrganizerCollections,
} from './types';

interface ViewData {
    website: string;
    events: DiscoveryEventsFromView;
}

export interface AppProps {
    api_data: {
        organizer: Organizer;
        organizer_collections: OrganizerCollections<OrganizerCollection>;
    };
    view_data: ViewData;
    facebookOptions: {
        appId: string;
        version: string;
        xfbml: boolean;
    };
    captchaKeyV2: string;
    featureFlags: any;
    hasPermissionToEditOrganizationInfo: boolean;
}

export const App: React.FunctionComponent<
    CoreApplicationContext & AppProps & CoreGoogleAnalyticsSettings
> = (props) => {
    const {
        enableOrganizerProfileRedesign,
        enableDisplayRatings,
        enableIaGlobalNav,
    } = props.featureFlags;

    const headerProps = {
        organizer: props.api_data.organizer,
        website: props.view_data.website,
        hasPermissionToEditOrganizationInfo:
            props.hasPermissionToEditOrganizationInfo,
        userId: props.user.publicId,
    };

    const mainProps = {
        organizer: props.api_data.organizer,
        events: props.view_data.events,
        organizerCollections: props.api_data.organizer_collections,
        userId: props.user.publicId,
        enableOrganizerProfileRedesign: enableOrganizerProfileRedesign,
        localeSubdirectory:
            props.env.localeInfo.uses_language_subdirectory &&
            props.env.localeInfo.locale
                ? `/${props.env.localeInfo.locale}`
                : '',
    };

    const onTrackingUserDropdown = () => {
        trackAnalyticsEvent({
            action: GA_ACTION_PROFILE_DROPDOWN,
            category: GA_CATEGORY_HEADER,
            label: GA_LABEL_CREATOR_EVENT,
        });
    };

    const sessionSampleRate =
        props.env.ebDomain.indexOf('eventbrite') > -1
            ? ORGANIZER_PROFILE_RUM_SAMPLE_RATE
            : 0;

    useRealUserMonitoring({
        sessionSampleRate,
        version: props.app_version,
        service: props.app_name,
    });
    const parentLocale = getParentLocale(
        getLocaleOverride() || props.env.localeInfo.locale,
    );
    useConstructor(() => {
        setLanguage(parentLocale);
    });

    return (
        <SiteStructure
            env={props.env}
            request={props.request}
            user={props.user}
            globalHeader={RenderGlobalNavOrUndefined({
                config: { user: props.user, env: props.env },
                featureFlag: enableIaGlobalNav,
            })}
            footerLinks={props.footerLinks}
            fullScreenMain={true}
            featureFlags={props.featureFlags}
            onMouseEnterGlobalHeader={onTrackingUserDropdown}
        >
            <TrustSignalsProvider enableDisplayRatings={enableDisplayRatings}>
                <UserProvider user={props.user}>
                    <OrganizerContext.Provider value={props.api_data.organizer}>
                        <EnvContext.Provider
                            value={{
                                gaSettings: props.gaSettings,
                                env: props.env,
                                facebookOptions: props.facebookOptions,
                                captchaKey: props.captchaKeyV2,
                            }}
                        >
                            <SurfacingExperimentProvider>
                                <OrganizerRatingDataHandler>
                                    {enableOrganizerProfileRedesign ? (
                                        <HeaderRedesign {...headerProps} />
                                    ) : (
                                        <Header {...headerProps} />
                                    )}
                                    <Main {...mainProps} />
                                </OrganizerRatingDataHandler>
                            </SurfacingExperimentProvider>
                        </EnvContext.Provider>
                    </OrganizerContext.Provider>
                </UserProvider>
            </TrustSignalsProvider>
        </SiteStructure>
    );
};
