import { useCallback, useEffect, useState } from 'react';
import { yieldToMain } from '../utils';

export default function useYielded() {
    const [yielded, setYielded] = useState(false);
    const waitForYield = useCallback(async () => {
        await yieldToMain();
        setYielded(true);
    }, []);

    useEffect(() => {
        waitForYield();
    }, [yielded, waitForYield]);

    return yielded;
}
